<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Unit Icons">
                    <template v-slot:description>
                        <div>
                            <div>!! deprecated !! do not use anymore !!</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-notification type="caution" :dismissable="false">This component is deprecated. Please do not use this anymore. Use the <hb-link to="/design-system/space-icons">HbSpaceIcon</hb-link> component instead.</hb-notification>

        <hb-card title="Avatar Unit Icon Examples + Code" class="mt-4 mb-6">

            <v-row no-gutters class="py-4 px-9">
                <v-col>
                    <div class="d-flex justify-center">
                        <HbUnitIcon avatar type="storage" />
                    </div>
                    <div class="mt-2 d-flex justify-center">
                    Storage
                    </div>
                </v-col>

                <v-spacer></v-spacer>

                <v-col>
                    <div class="d-flex justify-center">
                        <HbUnitIcon avatar type="parking" />
                    </div>
                    <div class="mt-2 d-flex justify-center">
                    Parking
                    </div>
                </v-col>
            </v-row>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbUnitIcon avatar type="storage" />

&lt;HbUnitIcon avatar type="parking" />
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Small Unit Icon Examples + Code" class="mt-4 mb-6">

            <v-row no-gutters class="py-4 px-9">
                <v-col>
                    <div class="d-flex justify-center">
                        <HbUnitIcon small type="storage" color="#101318" />
                    </div>
                    <div class="mt-2 d-flex justify-center">
                    Storage
                    </div>
                </v-col>

                <v-spacer></v-spacer>

                <v-col>
                    <div class="d-flex justify-center">
                        <HbUnitIcon small type="parking" color="#101318" />
                    </div>
                    <div class="mt-2 d-flex justify-center">
                    Parking
                    </div>
                </v-col>
            </v-row>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbUnitIcon small type="storage" color="#101318" />

&lt;HbUnitIcon small type="parking" color="#101318" />
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Default Size Unit Icon Examples + Code" class="mt-4 mb-6">

            <v-row no-gutters class="py-4 px-9">
                <v-col>
                    <div class="d-flex justify-center">
                        <HbUnitIcon type="storage" color="#101318" />
                    </div>
                    <div class="mt-2 d-flex justify-center">
                    Storage
                    </div>
                </v-col>

                <v-spacer></v-spacer>

                <v-col>
                    <div class="d-flex justify-center">
                        <HbUnitIcon type="parking" color="#101318" />
                    </div>
                    <div class="mt-2 d-flex justify-center">
                    Parking
                    </div>
                </v-col>
            </v-row>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbUnitIcon type="storage" color="#101318" />

&lt;HbUnitIcon type="parking" color="#101318" />
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemUnitIcons",
        data: function() {
            return {
                propHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'type', type: 'string', default: 'undefined', description: 'This sets the icon that is displayed. Refer to the design system "tokens.js" file for all possible space types that can be passed into this prop.' },
                    { name: 'avatar', type: 'boolean', default: 'false', description: 'If changed to true. Applies the avatar circle styling around the icon. Only will work for default size.' },
                    { name: 'small', type: 'boolean', default: 'false', description: 'If changed to true, the small sized icon will appear. This icon size is commonly used in places like <hb-data-table> and <hb-info-card> and other places.' },
                    { name: 'color', type: 'string', default: '#637381', description: 'Manually change the color of the icon.' },
                    { name: 'backgroundColor', type: 'string', default: '#ffffff', description: 'When default size with the avatar circle element applied, changing the hex code value here will change the background color of the avatar circle.' }
                ]
            };
        },
    }
</script>

<style scoped>

</style>
